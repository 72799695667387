<template>
  <div class="product-introduction">
    <Header/>
    <img src="@/assets/img/mobile/product_banner.png" alt="" class="banner">
    <div class="product-content">
      <h2 class="title">小诺肌骨<br><span>您的私人康复专家</span></h2>
      <p class="introduce">
        小诺肌骨团队联合三甲医院专家，开发出”小诺肌骨“智能 康复产品，基于智能可穿戴设备，依托云服务数据平台，为患者提供远程康复方案。
        该产品包含康复科普、数字测评、运动处方、智能可穿戴设备监测、数据反馈平台等服务模块，实现一对一远程个性化康复。
      </p>
      <h2 class="title">康复服务</h2>
      <div class="pain-spot">
        <div>
          <img src="@/assets/img/mobile/rehabilitation_services1.jpg" alt="">
          <div>慢性关节疼痛</div>
        </div>
        <div>
          <img src="@/assets/img/mobile/rehabilitation_services2.svg" alt="">
          <div>骨科术后康复</div>
        </div>
        <div>
          <img src="@/assets/img/mobile/rehabilitation_services3.svg" alt="">
          <div>体姿体态矫正</div>
        </div>
        <div>
          <img src="@/assets/img/mobile/rehabilitation_services4.svg" alt="">
          <div>运动损伤康复</div>
        </div>
      </div>
      <h2 class="title">专注科学康复<br><span>安全有效可靠</span></h2>
      <div class="data-box">
        <div>
          <div class="data">68.5<span>%</span></div>
          <div>疼痛程度降低</div>
        </div>
        <div>
          <div class="data">57.9<span>%</span></div>
          <div>抑郁评分降低</div>
        </div>
        <div>
          <div class="data">58.3<span>%</span></div>
          <div>焦虑评分降低</div>
        </div>
      </div>
      <div class="data-box data-box1">
        <div>
          <div class="data">30.1<span>%</span></div>
          <div>步行能力提升</div>
        </div>
        <div>
          <div class="data">65.9<span>%</span></div>
          <div>功能测评评分提高</div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

export default {
  name: 'ProductIntroduction',
  components: { Header, Footer },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.product-introduction {
  padding-top: 0.48rem;

  .product-content {
    padding: 0 0.18rem;

    h2.title:first-child {
      text-align: left;
      margin-bottom: 0.12rem;
    }
  }

  .banner {
    display: block;
    width: 100%;
    height: 2.13rem;
    margin-bottom: 0.36rem;
  }

  h2.title {
    font-size: 0.18rem;
    line-height: 0.24rem;
    color: #00aebb;
    padding: 0 0.12rem;
    margin-bottom: 0.24rem;
    text-align: center;

    span {
      font-weight: 400;
    }
  }

  .introduce {
    font-size: 0.12rem;
    line-height: 0.24rem;
    color: #1a1a1a;
    padding: 0 0.12rem;
    margin-bottom: 0.24rem;
  }

  .pain-spot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.12rem;
    color: #1a1a1a;
    margin-bottom: 0.33rem;

    > div {
      width: 1.6rem;
      text-align: center;
      margin-bottom: 0.15rem;

      img {
        width: 100%;
        height: 0.86rem;
        margin-bottom: 0.09rem;
        border-radius: 0.06rem;
      }
    }
  }

  .data-box {
    padding: 0 0.12rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      text-align: center;
      margin-bottom: 0.3rem;

      .data {
        font-size: 0.36rem;
        font-weight: 700;
        color: #00aebb;
        margin-bottom: 0.06rem;

        span {
          font-size: 0.12rem;
        }
      }

      > div:last-child {
        font-size: 0.1rem;
        line-height: 0.12rem;
        color: #1a1a1a;
      }
    }
  }

  .data-box1 {
    padding-bottom: 0.12rem;
    justify-content: space-evenly;
  }
}
</style>